import { useContext, useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { DeSoIdentityContext } from "react-deso-protocol";
import { getDisplayName, getFullDisplayName } from "../helpers";
import { identity, updateProfile, updateFollowingStatus, getUsersStateless } from "deso-protocol";
import userPrefsStore from 'context/userPrefsStore';
import { Loader2, X } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion"
import { Link } from "components/ui/button"
import { Skeleton } from "components/ui/skeleton"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { Switch } from "components/ui/switch"
import Turnstile, { useTurnstile } from "react-turnstile";
import party from "party-js";
import React, { useRef } from 'react';
import {
  Avatar,
  AvatarImage,
  AvatarFallback
} from "components/ui/avatar";
import desocialWorldLogo from "../assets/desocialworld-bw.svg";
import shilley from "../assets/shilly.png";
import diamondLogo from "../assets/diamond_logo_bw.svg";
import nftzLogo from "../assets/nftz-logo-bw.svg";
import desoStep1 from "../assets/step1.png";
import desoStep2 from "../assets/step2.jpg";
import desoStep3 from "../assets/step3.png";

export const Faq = () => {
  const ICON_SIZE = 48;
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetTwitterAPI, setResetTwitterAPI] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isFormVisible, setFormVisible] = useState(true);
  const [isSyncEnabled, setIsSyncEnabled] = useState(true);  // Default to true (enabled)
  const [hasHashtagError, setHasHashtagError] = useState(false);
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);
  const [hasClaimableNFT, setHasClaimableNFT] = useState(false);
  const [allNFTsClaimed, setAllNFTsClaimed] = useState(false);
  const [userHasClaimed, setUserHasClaimed] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [edition, setEdition] = useState("");
  const [series, setSeries] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const [postHashHex, setPostHashHex] = useState(null);
  const cardRef = useRef(null); 
  const [userHasNoProfile, setUserHasNoProfile] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewUserJustClaimed, setIsNewUserJustClaimed] = useState(false);
  const [username, setUsername] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [canSubmit, setCanSubmit] = useState(true);
  const debounceRef = useRef(null);
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [modalErrorMessage, setModalErrorMessage] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showOnboardNewUserModal, setShowOnboardNewUserModal] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewUserNowExplore, setIsNewUserNowExplore] = useState(false);
  const ownedCopiesCountRef = useRef(0);
  const numNFTCopiesRef = useRef(0);
  const numNFTCopiesClaimedRef = useRef(0);
  const [turnstileToken, setTurnstileToken] = useState(null);
  
  
  
  function DisplayAvatarsFollow() {
    const usersAvatars = [
      'BC1YLjGBU1pv4Wzc37r7qp4Xw9ZWHEzQ2Z4L6YBP9CVDfTWmWFaxYoD',
      'BC1YLgk64us61PUyJ7iTEkV4y2GqpHSi8ejWJRnZwsX6XRTZSfUKsop',
      'BC1YLi3PRWAFHHWjVxfAivYQbAiXoGnsLkeLkpejxSuiqgiyfxMgYXo',
      'BC1YLiuKfzE6HjurKQi156kzhUo8LGQWDUvudhkEPuqDZWe1NrdeLmV',
      'BC1YLfiUNarmxM7BAGAFHTvp6oDgjF4hMP3ZaNhkdn1LdDhncWzQT6w',
      'BC1YLgUt48Tcaahw2kFCuNh31Kv2Ep2KFKn2CXjbPET5dZYybM5gXhg',
      'BC1YLfiuB9oWiHDxntaWJWKkYCCQ7Uo2qWfLuJY4L5tP2rX7dunkZwS'
    ];
    return (
      <div className="flex justify-center space-x-[calc(-1*1rem)]">
        {usersAvatars.map(publicKey => (
          <Avatar key={publicKey}>
            <AvatarImage src={`https://node.deso.org/api/v0/get-single-profile-picture/${publicKey}?fallback=https://node.deso.org/assets/img/default_profile_pic.png`} />
            <AvatarFallback>U</AvatarFallback>
          </Avatar>
        ))}
      </div>
    );
  }
  
  function DisplayDCAvatars() {
    const [dcKeys, setDcKeys] = useState([]);
  
    useEffect(() => {
      const fetchDCAvatars = async () => {
        try {
          const response = await getUsersStateless({
            PublicKeysBase58Check: ['BC1YLjVbWMR8agP51We2HcMuhBw9ZY4e6TmgH2u5gkxzEMrxeKQWB4C'],
          });
  
          if (response && response.UserList && response.UserList[0]) {
            setDcKeys(response.UserList[0].PublicKeysBase58CheckFollowedByUser);
          }
        } catch (error) {
          console.error('Failed to fetch DC avatars:', error);
        }
      };
  
      fetchDCAvatars();
    }, []); // Empty dependency array to ensure this runs once on mount
  
    return (
      <div className="flex flex-wrap justify-center space-x-2">
        {dcKeys.map(publicKey => (
          <Avatar key={publicKey}>
          <a href={`https://desocialworld.com/u/${publicKey}`} target="_blank">
            <AvatarImage src={`https://node.deso.org/api/v0/get-single-profile-picture/${publicKey}?fallback=https://node.deso.org/assets/img/default_profile_pic.png`} />
            <AvatarFallback>U</AvatarFallback>
          </a>
        </Avatar>
        ))}
      </div>
    );
  }

  const signUpTutorial = () => {
    setShowOnboardNewUserModal(true);
  }
  const nextPage = () => {
      setCurrentPage(prevPage => Math.min(prevPage + 1, 3));
  };
  
  const prevPage = () => {
      setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      
      reader.onload = (loadEvent) => {
        setUploadedImage(loadEvent.target.result);
        setUploadedImageFile(file);
      };
  
      reader.readAsDataURL(file);
    }
  };

  const checkUsernameAvailability = async (username) => {
    try {
      if(username == '') {
        setAvailabilityMessage('');
        return
      }
      const response = await fetch('https://node.deso.org/api/v0/get-single-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Username: username.trim()
        }),
      });
  
      const data = await response.json();
  
      if (data.error && data.error.includes('GetSingleProfile: could not find profile')) {
        setAvailabilityMessage('This username is available');
        setCanSubmit(true);
      } else {
        setAvailabilityMessage('This username is not available');
        setCanSubmit(false);
      }
    } catch (error) {
      console.error('Error checking username:', error);
    }
  };

  const handleUsernameChange = (e) => {
    const enteredUsername = e.target.value;
    setUsername(enteredUsername);
  
    // Clear any existing debounce timeout
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
  
    // Set a new debounce timeout
    debounceRef.current = setTimeout(() => {
      checkUsernameAvailability(enteredUsername);
    }, 300);
  };

  const handleUpdateProfile = async () => {
    console.log("Running profile update function");
    // Validation checks
    if (!username || username.trim() === "") {
      setModalErrorMessage("Username cannot be blank.");
      setIsSubmitting(false);
      return;
  }

  if (!uploadedImage) {
      setModalErrorMessage("Please select a profile image.");
      setIsSubmitting(false);
      return;
  }
    console.log("Checking permissions");
    setIsSubmitting(true);
    setModalErrorMessage(null); // Reset any previous errors
  
  console.log("inside update profile");
  // Extract the current user's public key from context
  const updaterPublicKey = currentUser.PublicKeyBase58Check;
  
  // Strip trailing spaces from the inputted username
  const cleanedUsername = username.trim();
  try {
    // Construct the payload
    const payload = {
      UpdaterPublicKeyBase58Check: updaterPublicKey,
      NewUsername: cleanedUsername,
      NewProfilePic: uploadedImage,
      MinFeeRateNanosPerKB: 1500,
      NewCreatorBasisPoints: 10000,
      NewStakeMultipleBasisPoints: 12500,
      ProfilePublicKeyBase58Check: "",
      IsHidden: false
    };
    console.log("sending request for update profile");
    // Call the updateProfile function to update the profile
    const response = await updateProfile(payload);
    console.log("sent request for update profile");
    console.log("Profile update response:");
    console.log(response);
     
    setIsModalVisible(false);
    setIsNewUser(true)
    identity.setActiveUser(currentUser.PublicKeyBase58Check)
     
  } catch (error) {
      setModalErrorMessage(error.message || "An unexpected error occurred.");
  } finally {
      setIsSubmitting(false);
  }
};

  const getDerivedData = async () => {
    if (currentUser && currentUser.PublicKeyBase58Check) {
      const desoIdentityUsers = localStorage.getItem("desoIdentityUsers");
      const parsedData = JSON.parse(desoIdentityUsers);
      const targetUserKey = currentUser.PublicKeyBase58Check;

      let derivedDeSo;
      let retryCount = 0;

      while(retryCount < 3){
          derivedDeSo = parsedData[targetUserKey]?.primaryDerivedKey?.transactionSpendingLimits?.GlobalDESOLimit;

          if(derivedDeSo !== undefined){
              break;
          }

          await new Promise(r => setTimeout(r, 1000));
          retryCount++;
      }

      return {
        derivedPublicKey: parsedData[targetUserKey]?.primaryDerivedKey?.derivedPublicKeyBase58Check,
        derivedSeedHex: parsedData[targetUserKey]?.primaryDerivedKey?.derivedSeedHex,
        derivedDeSo: derivedDeSo,
        expirationBlock: parsedData[targetUserKey]?.primaryDerivedKey?.expirationBlock,
        accessSignature: parsedData[targetUserKey]?.primaryDerivedKey?.accessSignature,
        transactionSpendingLimitHex: parsedData[targetUserKey]?.primaryDerivedKey?.transactionSpendingLimitHex
      };
    }
    return { derivedPublicKey: null, derivedSeedHex: null, derivedDeSo: null, expirationBlock: null, accessSignature: null, transactionSpendingLimitHex: null };
  }

const creatorsPool = ['BC1YLjTpWm4roMBq5AniLyWdFBqB6W14of9ULAzb4KEsRkxdGzR7JmU', 'BC1YLjRoRqcFQ7GEx5NsthCjk8Sn11oT946yrKAPzFiuYzmmk3FokhR', 'BC1YLhkuDy1Ehw5fnPT54R6VnEkFuxkVtFKB8bPKvN7p3DpG251ibRT', 'BC1YLhyuDGeWVgHmh3UQEoKstda525T1LnonYWURBdpgWbFBfRuntP5', 'BC1YLgi66tdjAaVfYpmM447cxsve3TpvfXD9h8X6JMak7gbKABoEVaT', 'BC1YLiZMd4fmvjscAvKic3rzowN6gbKkQfvR6iBEMMk97oFZ24PPDua', 'BC1YLgk64us61PUyJ7iTEkV4y2GqpHSi8ejWJRnZwsX6XRTZSfUKsop', 'BC1YLi9cLKJkZ7pLyvLz14dAeJfdXvhYYRRQxZRvCXmchuQpNU7ovQn', 'BC1YLhmfDtNX88bmVdiWEypafM2nRcyFHMeoW9gy8TT5PbNCevXkT8L', 'BC1YLiqkd6TLPAStVYciCJxudJy3JQq83X2Qp4Banc8HE7K68up6Qzs', 'BC1YLjTPoBX5Ajma3yaMLUnMfjsYvErtT6v18NQ467MdpohhXSDuQ5T', 'BC1YLjWERF3xWcAD3SeCqtnRwF3FvhoXScZmF5TECd98qeCZpEzgsJD', 'BC1YLgzcfyi5GZoMb9xoVzDCMy9KEzzvTqoJzRrVDfhWE2FfFubxaVm', 'BC1YLhBLE1834FBJbQ9JU23JbPanNYMkUsdpJZrFVqNGsCe7YadYiUg',  'BC1YLirtb7CjNwVmWEt7t1487Qpo4LoPBDEGvfqYwXXZcj2dDLNMBVU', 'BC1YLiLhFcR5Jct4qXGaqVjhFgHzwE8fMKX9diibqzqmY78ZavNvCm5', 'BC1YLgNC8GkLTpzaJzq9GueaBsPNYH72PzmZ7YcfPCGy3g1ZFwXqdWK', 'BC1YLgdHdP7Q1L3TwUhjrQ28YCawU5e8RcGPvhY12DRtKHNkCxNxdbG', 'BC1YLhhwL8D3SE1ABwgWuwUjjFYGF64hsYHuToLyhPguDbGG5WhNDF3', 'BC1YLgNhcE7XKy4ypUkcvLeCLTBUdW49oz4aSKnUkjztU7GQyyCYweq'];
const publicKeysToFollow = ['BC1YLg3LGWznB8Qhw3u56DKtFTeUz2qtwgN7BNuAZVzUNKkhDhNApUM','BC1YLjGBU1pv4Wzc37r7qp4Xw9ZWHEzQ2Z4L6YBP9CVDfTWmWFaxYoD', 'BC1YLh3xfZeXxLNnMaMwhvnTBWozbyoWbDzzyk5ydh6rikNdzPuYEY4', 'BC1YLi3PRWAFHHWjVxfAivYQbAiXoGnsLkeLkpejxSuiqgiyfxMgYXo', 'BC1YLiuKfzE6HjurKQi156kzhUo8LGQWDUvudhkEPuqDZWe1NrdeLmV', 'BC1YLfiUNarmxM7BAGAFHTvp6oDgjF4hMP3ZaNhkdn1LdDhncWzQT6w', 'BC1YLgUt48Tcaahw2kFCuNh31Kv2Ep2KFKn2CXjbPET5dZYybM5gXhg', 'BC1YLfiuB9oWiHDxntaWJWKkYCCQ7Uo2qWfLuJY4L5tP2rX7dunkZwS', 'BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2', 'BC1YLiwZ1bd8xdUmXPoLMJtBVEN9hGUvayATeDfRq4Upwed6N2YMi68', 'BC1YLgfU6jFEoEW5FyDtBesygjyF6k5ULt2ycp5YKPLGuTMJhLb5qHo', 'BC1YLjUwv6pvUJVKhaNWwUPWgu8AfhaNJcof55VLx8cFuegUF4ZGACT', 'BC1YLi6wzmjQ7vKzj5jHY5CPXwfEJ9cUEK4Z8JnRqS3vqbYbszAbhuK'];


//let token
  //try {
  //  const response = await fetch('https://kzj8hu7m70.execute-api.us-west-2.amazonaws.com/production/follow', {
  //    method: 'POST',
  //    headers: {
  //      'Content-Type': 'application/json'
  //    },
  //    body: JSON.stringify({ 
  //      public_key: followerPublicKey,
  //      captcha_site_key: token
  //     })
  //  });
  //  
  //  const data = await response.json();
//
  //  if (data.statusCode !== 200) {
  //    throw new Error('Failed to make the POST request');
  //  }
  //} catch (error) {
  //  console.error('Error with POST request:', error);
  //  return;  // If the POST request fails, we don't continue
  //}


async function followMultipleKeys() {
  console.log("Follow")

  // Check if the user has permissions
  const hasPermission = await identity.hasPermissions({
    GlobalDESOLimit: 10000000, // 0.01 DESO
    TransactionCountLimitMap: {
      FOLLOW: 100,
    },
  });
  if (!hasPermission) {
    // Request permissions from the user
    await identity.requestPermissions({
    GlobalDESOLimit: 10000000, // 0.01 DESO
    TransactionCountLimitMap: {
      FOLLOW: 100,
    },
    });
  }
  const followerPublicKey = currentUser.PublicKeyBase58Check;
  // Go ahead and close - other stuff happens in the background
  setIsNewUserNowExplore(true);
  setIsNewUserJustClaimed(false);
  
  // follow the DC team
  const theDCteam = await getUsersStateless({
    PublicKeysBase58Check: ['BC1YLjVbWMR8agP51We2HcMuhBw9ZY4e6TmgH2u5gkxzEMrxeKQWB4C'],
  });
  // Extracting PublicKeysBase58CheckFollowedByUser from the response
  const allKeysToFollow = theDCteam.UserList[0].PublicKeysBase58CheckFollowedByUser;


  const followPromises = allKeysToFollow.map(publicKey => {
    const request = {
      "IsUnfollow": false,
      "FollowedPublicKeyBase58Check": publicKey,
      "FollowerPublicKeyBase58Check": followerPublicKey
    };

    return updateFollowingStatus(request)
    .catch(error => {
      // Check if the error is because the follow already exists
      if (error.message.includes("RuleErrorFollowEntryAlreadyExists")) {
        console.log(`Already following user with key: ${publicKey}`);
        return null; // Or some indication that this was not a critical error
      }
      // Propagate other errors
      throw error;
    });
  });

  const responses = await Promise.all(followPromises);
  return responses;
}



  
  useEffect(() => {
    
    setIsSubmissionSuccessful(false);
    setApiResponse(null);
    // Extract posthashhex from the URL
    const postHashHex = window.location.pathname.split('/claim/')[1];
    setPostHashHex(postHashHex);

    // Check if the user has a profile
    if (currentUser && currentUser.PublicKeyBase58Check) {
      fetch('https://node.deso.org/api/v0/get-single-profile', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              PublicKeyBase58Check: currentUser.PublicKeyBase58Check
          })
      })
      .then(response => response.json())
      .then(data => {
          if (data.error && data.error.includes('GetSingleProfile: could not find profile')) {
              setUserHasNoProfile(true);
              console.log("User has no profile")
              setIsModalVisible(true);
          } else {
              setUserHasNoProfile(false);
              setIsModalVisible(false);
          }
      })
      .catch(error => {
          console.error('Error fetching profile data:', error);
      });
    }
  }, [currentUser]);

  return (
    <>
    
      { apiError ? <p>Error: {apiError}</p> : null }
      <div className="flex-wrap items-center justify-center">
        <h1 className="text-5xl font-extrabold">New DeSo User FAQ</h1>
        </div>
        <div className="max-w-3xl mx-auto p-4 text-left">
    <Accordion type="single" collapsible>
    {/* Question 1 */}
    <AccordionItem value="item-1">
      <AccordionTrigger>What are some basic things I should know when joining DeSo?</AccordionTrigger>
      <AccordionContent>
      <ul className="list-disc pl-5">
          <li>Do not reduce your founder reward until you thoroughly understand how creator coins work! Keep it at 100% initially. And watch this helpful video by <a href="https://desocialworld.com/posts/327df15297d4b57f2580559c4e18873e672996f41dbc150860784da34a950ba2" target="_blank" rel="noopener noreferrer">@jodybossert</a>.</li>
          <li>Buy your own creator coin and don't sell it. This is like owning stock in yourself. A good amount to own is 5-10 coins.</li>
          <li>Be aware of scams. Always verify profiles and NFTs are tied to real identities before investing.</li>
          <li>You can monetize content by getting tipped diamonds, which go directly to your DeSo wallet.</li>
          <li>You can make NFTs by creating a post, clicking the top menu buttons, and selecting "mint NFT".</li>
          <li>Update your profile to include specific information about you and what you want others to know about you. Use <a href="https://linktr.ee" target="_blank" rel="noopener noreferrer">linktr.ee</a> to share your other social media profiles.</li>
          <li>All actions are recorded permanently on the blockchain, so be thoughtful.</li>
          <li>A blue checkmark only means verified on another platform or verified by the DeSo node you are using. It does not mean they are trustworthy or worth investing in.</li>
      </ul>
      </AccordionContent>
    </AccordionItem>

    {/* Question 2 */}
    <AccordionItem value="item-2">
      <AccordionTrigger>How can I gain more visibility and engagement on DeSo?</AccordionTrigger>
      <AccordionContent>
      <ul className="list-disc pl-5">
          <li>Be active, post consistently multiple times per day.</li>
          <li>Fully complete your bio with links to other profiles.</li>
          <li>Use relevant tags and mentions to improve reach.</li>
          <li>Connect your content to current events and conversations.</li>
          <li>Be genuine and personable in your posts.</li>
          <li>Explore different feeds like <a href="https://desocialworld.com/u/desofy" target="_blank" rel="noopener noreferrer">@desofy</a> to find new users.</li>
          <li>Reply to comments and comment on posts.</li>
          <li>Follow relevant active creators.</li>
          <li>Interact with higher value creator coins to elevate your visibility.</li>
      </ul>
      </AccordionContent>
    </AccordionItem>

    {/* Question 3 */}
    <AccordionItem value="item-3">
      <AccordionTrigger>What are some ways I can support the DeSo community?</AccordionTrigger>
      <AccordionContent>
      <ul className="list-disc pl-5">
          <li>Join a Twitter Space, Clubhouse, <a href="https://desocialworld.com/u/VibeHut" target="_blank" rel="noopener noreferrer">@VibeHut</a> or <a href="https://desocialworld.com/u/Entre" target="_blank" rel="noopener noreferrer">@Entre</a> room and actively participate.</li>
          <li>Consider moderating a chat room or hosting your own video room.</li>
          <li>Support fellow creators by buying their creator coins, NFTs, and tipping diamonds (not just likes).</li>
          <li>Repost content you enjoy to your followers.</li>
      </ul>
      </AccordionContent>
    </AccordionItem>

    {/* Question 4 */}
    <AccordionItem value="item-4">
      <AccordionTrigger>How much of my own creator coin should I own?</AccordionTrigger>
      <AccordionContent>
      <ul className="list-disc pl-5">
          <li>It’s a good rule of thumb to purchase at least 5-10 of your own creator coin while the founder’s reward is still set at 100%. This is the lowest it will ever be priced and it prevents others from pumping and dumping your coin.</li>
          <li>Leave enough DeSo in your wallet for other activities like investing and transactions.</li>
      </ul>

      </AccordionContent>
    </AccordionItem>

    {/* Question 5 */}
    <AccordionItem value="item-5">
      <AccordionTrigger>When should I reduce my creator coin founder reward below 100%?</AccordionTrigger>
      <AccordionContent>
      <ul className="list-disc pl-5">
          <li>Do not reduce your founder reward until you thoroughly understand how creator coins work! Keep it at 100% initially. And watch this helpful video by <a href="https://desocialworld.com/posts/327df15297d4b57f2580559c4e18873e672996f41dbc150860784da34a950ba2" target="_blank" rel="noopener noreferrer">@jodybossert</a>.</li>
          <li>You don’t ever have to lower your founder reward if you don’t want to!</li>
          <li>But if you do decide to lower it, reduce it slowly over time, after establishing your presence and reputation. 20% can be a reasonable place to start.</li>
      </ul>

      </AccordionContent>
    </AccordionItem>

    {/* Question 6 */}
    <AccordionItem value="item-6">
      <AccordionTrigger>Can I sell my own creator coin?</AccordionTrigger>
      <AccordionContent>
      <ul className="list-disc pl-5">
          <li>If you sell your own creator coin it is called “rugging” and is looked down upon by the DeSo community (you might even be labeled a scammer if you do this because your creator coin holders can lose a lot of money if you sell). A better option is to use your own coin in giveaways or sending small amounts to creators you appreciate as a thank you.</li>
          <li>It’s better to view your own coin as investing in yourself, and a way to give confidence to other investors that you are in for the long-run. If you do sell your own coin, that will damage your reputation and because everything is stored on-chain, that information will always be attached to your account.</li>
      </ul>

      </AccordionContent>
    </AccordionItem>

    {/* Question 7 */}
    <AccordionItem value="item-7">
      <AccordionTrigger>What is Openfund and how does it work?</AccordionTrigger>
      <AccordionContent>
      <ul className="list-disc pl-5">
          <li><a href="https://openfund.com" target="_blank" rel="noopener noreferrer">Openfund</a> is a platform built on the DeSo blockchain that makes it easy for founders and teams to raise capital for their projects using cryptocurrency. It makes creating a fundraising campaign as easy as setting up a social media profile.</li>
          <li>You can also use Openfund to trade project tokens with market orders or limit orders.</li>
          <li>Want to use Openfund? Check out this tutorial by <a href="https://desocialworld.com/u/Triangle_Traders/blog/openfund-tutorial" target="_blank" rel="noopener noreferrer">@TriangleTraders: Openfund Tutorial</a></li>
      </ul>

      </AccordionContent>
    </AccordionItem>

    {/* Question 8 */}
    <AccordionItem value="item-8">
      <AccordionTrigger>How can I avoid getting scammed on DeSo?</AccordionTrigger>
      <AccordionContent>
        <p>Scams unfortunately exist on DeSo, just like other platforms. Here are some tips to stay safe:</p>
        <ul className="list-disc pl-5">
          <li>Follow <a href="https://desocialworld.com/u/desoscams" target="_blank" rel="noopener noreferrer">@desoscams</a> for regular updates on known scammers.</li>
          <li>Carefully research any profile or NFT before investing money or coins. Scammers often impersonate real people.</li>
          <li>Check that profiles have genuine engagement and content history. Fake accounts tend to have little or no actual community interaction.</li>
          <li>Search names on <a href="https://explorer.deso.com" target="_blank" rel="noopener noreferrer">explorer.deso.com</a> to view detailed analytics on wallets and transactions. This can reveal suspicious activity.</li>
          <li>Watch out for "too good to be true" investment opportunities with guaranteed returns. Any guarantees are likely scams.</li>
          <li>Be wary of accounts pressuring you to invest quickly without allowing time for due diligence. Rushing is a red flag.</li>
          <li>Ask questions in chat rooms on <a href="https://desocialworld.com/u/VibeHut" target="_blank" rel="noopener noreferrer">@VibeHut</a> if you are uncertain about someone's legitimacy. The community is usually happy to warn about potential scams.</li>
          <li>Seek second opinions before sending money or coins to unfamiliar people. It's better to be safe than fall for scams.</li>
        </ul>
      </AccordionContent>
    </AccordionItem>

    {/* Add more items as needed */}
  </Accordion>
        </div>
      
          <div className="flex justify-center">
          <Card className="lg:w-1/3 mb-10" ref={cardRef}>
              <CardHeader className="px-6 pb-1">
                <h1 className="text-4xl font-extrabold mb-2">Follow the DC team!</h1>
              </CardHeader>
              <CardContent>
              { !currentUser &&
        <>
        <div className="flex-wrap items-center justify-center">
          <DisplayDCAvatars />
          </div>
    <p className="m-2 mb-3 italic">Hey there! In order to follow the DC team, you must first login with your DeSo account or you can create a new one for free if you don't have one yet!</p>
    <Button size="lg" className="mr-4 mb-2" onClick={() => identity.login()} variant="outline">Login</Button><Button size="lg" className="mb-2" onClick={signUpTutorial}>Create Account</Button>
    </>
  }
  { currentUser &&
        <>
    <p className="m-2 mb-3 italic">Click Shilley and automatically follow everyone on @TheDC team:</p>
    <button onClick={followMultipleKeys} className="w-full">
    <img src={shilley} alt="Shilley" style={{ cursor: 'pointer' }} />
    </button></>
  }
    </CardContent>
            </Card>

    

  
            
          </div>
          <div className="flex justify-center items-center">
      <Turnstile
      sitekey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY}
      executution="execute"
      onLoad={(widgetId, bound) => {
        // before:
        window.turnstile.execute(widgetId);
        // now:
        bound.execute();
      }}
      onVerify={(token) => {
        // Update the state variable with the received token.
        setTurnstileToken(token);
      }}
    />
    </div>
          
        

      
      
    {isModalVisible && (
  <>
    <div className="fixed inset-0 bg-black opacity-70 z-2"></div>
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black p-8 rounded-lg shadow-lg w-full max-w-2xl md:w-3/4">
        <h2 className="text-2xl font-bold text-white mb-4">Awesome, it's easy to join DeSo!</h2>
        <p className="text-gray-300">You just need to choose a username and upload a profile image:</p>
    
        {/* Username Input */}
        <div className="flex flex-col items-start w-full lg:w-3/4 gap-1.5 mx-auto mt-3">
          <Label htmlFor="username" className="text-1xl">Choose a username:</Label>
          <Input 
            id="userIdentifierDeso" 
            name="userIdentifierDeso" 
            type="text" 
            placeholder="Enter username"
            value={username}
            onChange={handleUsernameChange}
            autoComplete="off"
          />
          <p className={canSubmit ? 'text-green-500' : 'text-red-500'}>
            {availabilityMessage}
          </p>
        </div>
    

        {/* File Input */}
        <div className="flex items-center gap-1.5 w-full lg:w-3/4 mt-4 mx-auto justify-center">
          <label className="flex-grow relative block">
          <div className="items-start text-left mb-1.5">Choose Profile Image:</div>
            <input id="picture" type="file" onChange={handleImageChange}
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:bg-primary file:text-black hover:file:bg-primary/90 disabled:opacity-50 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 file:cursor-pointer" />
          </label>
          {uploadedImage && (
            <div>
              <Avatar className="h-[3.875rem] w-[3.875rem]">
                <AvatarImage src={uploadedImage} alt="Uploaded preview" />
                <AvatarFallback>U</AvatarFallback>
              </Avatar>
            </div>
          )}
        </div>

        {/* Submit Button */}
          <div className="mt-4 flex flex-col items-center"> 
            <Button
              disabled={isSubmitting || !canSubmit} 
              onClick={handleUpdateProfile}
            >
              {isSubmitting 
                ? (
                  <>
                    <Loader2 className="animate-spin" />
                    Please Wait...
                  </>
                ) 
                : 'Submit'
              }
            </Button>
            
            {modalErrorMessage && (
              <p className="mt-2 text-red-500">{modalErrorMessage}</p>
            )}
        </div>
      </div>
    </div>
  </>
)}
{ currentUser && (
  <>
  <div>
  <h1 className="text-2xl md:text-4xl text-center mt-16 mb-2 font-bold">Welcome to the world of Decentralized Social:</h1>
  </div>
  <div className="lg:w-2/3 justify-center lg:items-center mx-auto">
  <div className=' aspect-video ' >
    <iframe 
        className=' h-full w-full rounded-lg'
        src="https://www.youtube.com/embed/kPPb_S5Ry5E?si=jrNayaofOeRORN7S" 
        width="100%" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen> 
     </iframe>
</div> 
  </div>
  <div>
  <h1 className="text-2xl md:text-4xl text-center mt-16 mb-2 font-bold">All these apps and more use DeSo:</h1>
  <p className="mb-8 italic">You can login using your DeSo account on all these apps seemlessly!</p>
  </div>

<div className="lg:flex justify-center lg:items-center lg:w-2/3 mx-auto lg:space-x-6">

  <div className="flex-1 mb-4">
    <Card>
      <CardHeader className="flex justify-center items-center">
        <CardTitle>
          <a href="https://desocialworld.com" target="_blank" rel="noopener noreferrer">
            <img src={desocialWorldLogo} alt="DeSocialWorld" className="App-logo w-full" />
          </a>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-3">We make it easy for all non-English speaking people to use Decentralized Social.</p>
        <a href="https://desocialworld.com" target="_blank" rel="noopener noreferrer">
          <Button>Visit</Button>
        </a>
      </CardContent>
    </Card>
  </div>

  <div className="flex-1 mb-4">
    <Card>
      <CardHeader className="flex justify-center items-center">
        <CardTitle>
          <a href="https://nftz.me" target="_blank" rel="noopener noreferrer">
            <img src={nftzLogo} alt="NFTz" className="App-logo w-full" />
          </a>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-3">NFTz.me is a DeSo marketplace and community that aims to pave the way to seamlessly blend NFTs with Web3 services.</p>
        <a href="https://nftz.me" target="_blank" rel="noopener noreferrer">
          <Button>Visit</Button>
        </a>
      </CardContent>
    </Card>
  </div>

  <div className="flex-1 mb-4">
    <Card>
      <CardHeader className="flex justify-center items-center">
        <CardTitle>
          <a href="https://diamondapp.com" target="_blank" rel="noopener noreferrer">
            <img src={diamondLogo} alt="Diamond" className="App-logo w-full" />
          </a>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-3">Join the future of web3 and social media onchain. Built on DeSo.</p>
        <a href="https://diamondapp.com" target="_blank" rel="noopener noreferrer">
          <Button>Visit</Button>
        </a>
      </CardContent>
    </Card>
  </div>

</div>

  </>
)}
{showOnboardNewUserModal && (
  <>
  <div className="fixed inset-0 bg-black opacity-70 z-2" onClick={() => setShowOnboardNewUserModal(false)}></div>
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="bg-black p-8 rounded-lg shadow-lg relative w-full max-w-2xl md:w-3/4 border">
        
        {/* Close Button */}
        <button className="absolute right-2 font-bold top-2 text-white" onClick={() => setShowOnboardNewUserModal(false)}>
        <X size={18} />
        </button>
          
          
          {currentPage === 1 && (
            <>
              <h2 className="text-2xl font-bold text-white mb-4">Signing up for a DeSo Account is easy!</h2>
              <p className="text-gray-300 mb-2">Step 1: When you click "Sign Up" a window will pop-up and you can choose to sign up with your Google account, MetaMask or you can "Sign up with DeSo Seed":</p>
              <img src={desoStep1} alt="Step 1" className="rounded border bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800" />
            </>
          )}
          
          {currentPage === 2 && (
            <>
              <p className="text-gray-300 mb-2">Step 2: If you select to use a Seed Phrase be sure and copy your Seed Phrase in a safe place (you’ll need this to login - it’s like a password). Then on the next screen paste the Seed Phrase back into the text box.</p>
              <img src={desoStep2} alt="Step 2" className="rounded border bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800" />
            </>
          )}

          {currentPage === 3 && (
            <>
              <p className="text-gray-300 mb-2">Step 3: Then complete the captcha to receive some free DeSo and approve the signing access prompt. Ready? Go ahead and click the "Create Account" button!</p>
              <img src={desoStep3} alt="Step 3" className="rounded border bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800" />
            </>
          )}

            <div className="mt-4 flex justify-between items-center"> 
            
            {currentPage > 1 ? (
                <Button onClick={prevPage}>
                    Back
                </Button>
            ) : (
                <span></span>
            )}
            
            {currentPage < 3 ? (
                <Button onClick={nextPage}>
                    Next
                </Button>
            ) : (
              <Button onClick={() => {
                identity.login();
                setShowOnboardNewUserModal(false);
            }}>
                Create Account
            </Button>
            )}
            
            {modalErrorMessage && (
                <div className="w-full mt-2 text-red-500 text-center">{modalErrorMessage}</div>
            )}
            </div>
        </div>
      </div>

  </>
)}


    </>
    
);
}