export const getDisplayName = (user) => {
  const name = user.ProfileEntryResponse?.Username ?? user.PublicKeyBase58Check;
  return truncateString(name, 10);
}
export const getFullDisplayName = (user) => {
  const name = user.ProfileEntryResponse?.Username ?? user.PublicKeyBase58Check;
  return name;
}
const truncateString = (str, maxLength) => {
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength) + '...';
};
