import { useContext, useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { DeSoIdentityContext } from "react-deso-protocol";
import { getDisplayName, getFullDisplayName } from "../helpers";
import { identity, updateProfile, updateFollowingStatus, getUsersStateless } from "deso-protocol";
import userPrefsStore from 'context/userPrefsStore';
import { Loader2, X } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button"
import { Link } from "components/ui/button"
import { Skeleton } from "components/ui/skeleton"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { Switch } from "components/ui/switch"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Turnstile, { useTurnstile } from "react-turnstile";
import party from "party-js";
import React, { useRef } from 'react';
import {
  Avatar,
  AvatarImage,
  AvatarFallback
} from "components/ui/avatar";
import desocialWorldLogo from "../assets/desocialworld-bw.svg";
import diamondLogo from "../assets/diamond_logo_bw.svg";
import nftzLogo from "../assets/nftz-logo-bw.svg";
import desoStep1 from "../assets/step1.png";
import desoStep2 from "../assets/step2.jpg";
import desoStep3 from "../assets/step3.png";

export const Claim = () => {
  const ICON_SIZE = 48;
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetTwitterAPI, setResetTwitterAPI] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isFormVisible, setFormVisible] = useState(true);
  const [isSyncEnabled, setIsSyncEnabled] = useState(true);  // Default to true (enabled)
  const [hasHashtagError, setHasHashtagError] = useState(false);
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);
  const [hasClaimableNFT, setHasClaimableNFT] = useState(false);
  const [allNFTsClaimed, setAllNFTsClaimed] = useState(false);
  const [userHasClaimed, setUserHasClaimed] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [edition, setEdition] = useState("");
  const [series, setSeries] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const [postHashHex, setPostHashHex] = useState(null);
  const cardRef = useRef(null); 
  const [userHasNoProfile, setUserHasNoProfile] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewUserJustClaimed, setIsNewUserJustClaimed] = useState(false);
  const [username, setUsername] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [canSubmit, setCanSubmit] = useState(true);
  const debounceRef = useRef(null);
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [modalErrorMessage, setModalErrorMessage] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showOnboardNewUserModal, setShowOnboardNewUserModal] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewUserNowExplore, setIsNewUserNowExplore] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const ownedCopiesCountRef = useRef(0);
  const numNFTCopiesRef = useRef(0);
  const numNFTCopiesClaimedRef = useRef(0);
  const [turnstileToken, setTurnstileToken] = useState(null);
  
  
  function DisplayAvatarsFollow() {
    const usersAvatars = [
      'BC1YLjGBU1pv4Wzc37r7qp4Xw9ZWHEzQ2Z4L6YBP9CVDfTWmWFaxYoD',
      'BC1YLgk64us61PUyJ7iTEkV4y2GqpHSi8ejWJRnZwsX6XRTZSfUKsop',
      'BC1YLi3PRWAFHHWjVxfAivYQbAiXoGnsLkeLkpejxSuiqgiyfxMgYXo',
      'BC1YLiuKfzE6HjurKQi156kzhUo8LGQWDUvudhkEPuqDZWe1NrdeLmV',
      'BC1YLfiUNarmxM7BAGAFHTvp6oDgjF4hMP3ZaNhkdn1LdDhncWzQT6w',
      'BC1YLgUt48Tcaahw2kFCuNh31Kv2Ep2KFKn2CXjbPET5dZYybM5gXhg',
      'BC1YLfiuB9oWiHDxntaWJWKkYCCQ7Uo2qWfLuJY4L5tP2rX7dunkZwS'
    ];
    return (
      <div className="flex justify-center space-x-[calc(-1*1rem)]">
        {usersAvatars.map(publicKey => (
          <Avatar key={publicKey}>
            <AvatarImage src={`https://node.deso.org/api/v0/get-single-profile-picture/${publicKey}?fallback=https://node.deso.org/assets/img/default_profile_pic.png`} />
            <AvatarFallback>U</AvatarFallback>
          </Avatar>
        ))}
      </div>
    );
  }
  

  const signUpTutorial = () => {
    setShowOnboardNewUserModal(true);
  }
  const nextPage = () => {
      setCurrentPage(prevPage => Math.min(prevPage + 1, 3));
  };
  
  const prevPage = () => {
      setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      
      reader.onload = (loadEvent) => {
        setUploadedImage(loadEvent.target.result);
        setUploadedImageFile(file);
      };
  
      reader.readAsDataURL(file);
    }
  };

  const checkUsernameAvailability = async (username) => {
    try {
      if(username == '') {
        setAvailabilityMessage('');
        return
      }
      const response = await fetch('https://node.deso.org/api/v0/get-single-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Username: username.trim()
        }),
      });
  
      const data = await response.json();
  
      if (data.error && data.error.includes('GetSingleProfile: could not find profile')) {
        setAvailabilityMessage('This username is available');
        setCanSubmit(true);
      } else {
        setAvailabilityMessage('This username is not available');
        setCanSubmit(false);
      }
    } catch (error) {
      console.error('Error checking username:', error);
    }
  };

  const handleUsernameChange = (e) => {
    const enteredUsername = e.target.value;
    setUsername(enteredUsername);
  
    // Clear any existing debounce timeout
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
  
    // Set a new debounce timeout
    debounceRef.current = setTimeout(() => {
      checkUsernameAvailability(enteredUsername);
    }, 300);
  };

  const handleUpdateProfile = async () => {
    console.log("Running profile update function");
    // Validation checks
    if (!username || username.trim() === "") {
      setModalErrorMessage("Username cannot be blank.");
      setIsSubmitting(false);
      return;
  }

  if (!uploadedImage) {
      setModalErrorMessage("Please select a profile image.");
      setIsSubmitting(false);
      return;
  }
    console.log("Checking permissions");
    setIsSubmitting(true);
    setModalErrorMessage(null); // Reset any previous errors
  
  console.log("inside update profile");
  // Extract the current user's public key from context
  const updaterPublicKey = currentUser.PublicKeyBase58Check;
  
  // Strip trailing spaces from the inputted username
  const cleanedUsername = username.trim();
  try {
    // Construct the payload
    const payload = {
      UpdaterPublicKeyBase58Check: updaterPublicKey,
      NewUsername: cleanedUsername,
      NewProfilePic: uploadedImage,
      MinFeeRateNanosPerKB: 1500,
      NewCreatorBasisPoints: 10000,
      NewStakeMultipleBasisPoints: 12500,
      ProfilePublicKeyBase58Check: "",
      IsHidden: false
    };
    console.log("sending request for update profile");
    // Call the updateProfile function to update the profile
    const response = await updateProfile(payload);
    console.log("sent request for update profile");
    console.log("Profile update response:");
    console.log(response);
     
    setIsModalVisible(false);
    setIsNewUser(true)
    identity.setActiveUser(currentUser.PublicKeyBase58Check)
     
  } catch (error) {
      setModalErrorMessage(error.message || "An unexpected error occurred.");
  } finally {
      setIsSubmitting(false);
  }
};

  const getDerivedData = async () => {
    if (currentUser && currentUser.PublicKeyBase58Check) {
      const desoIdentityUsers = localStorage.getItem("desoIdentityUsers");
      const parsedData = JSON.parse(desoIdentityUsers);
      const targetUserKey = currentUser.PublicKeyBase58Check;

      let derivedDeSo;
      let retryCount = 0;

      while(retryCount < 3){
          derivedDeSo = parsedData[targetUserKey]?.primaryDerivedKey?.transactionSpendingLimits?.GlobalDESOLimit;

          if(derivedDeSo !== undefined){
              break;
          }

          await new Promise(r => setTimeout(r, 1000));
          retryCount++;
      }

      return {
        derivedPublicKey: parsedData[targetUserKey]?.primaryDerivedKey?.derivedPublicKeyBase58Check,
        derivedSeedHex: parsedData[targetUserKey]?.primaryDerivedKey?.derivedSeedHex,
        derivedDeSo: derivedDeSo,
        expirationBlock: parsedData[targetUserKey]?.primaryDerivedKey?.expirationBlock,
        accessSignature: parsedData[targetUserKey]?.primaryDerivedKey?.accessSignature,
        transactionSpendingLimitHex: parsedData[targetUserKey]?.primaryDerivedKey?.transactionSpendingLimitHex
      };
    }
    return { derivedPublicKey: null, derivedSeedHex: null, derivedDeSo: null, expirationBlock: null, accessSignature: null, transactionSpendingLimitHex: null };
  }

const creatorsPool = ['BC1YLjTpWm4roMBq5AniLyWdFBqB6W14of9ULAzb4KEsRkxdGzR7JmU', 'BC1YLjRoRqcFQ7GEx5NsthCjk8Sn11oT946yrKAPzFiuYzmmk3FokhR', 'BC1YLhkuDy1Ehw5fnPT54R6VnEkFuxkVtFKB8bPKvN7p3DpG251ibRT', 'BC1YLhyuDGeWVgHmh3UQEoKstda525T1LnonYWURBdpgWbFBfRuntP5', 'BC1YLgi66tdjAaVfYpmM447cxsve3TpvfXD9h8X6JMak7gbKABoEVaT', 'BC1YLiZMd4fmvjscAvKic3rzowN6gbKkQfvR6iBEMMk97oFZ24PPDua', 'BC1YLgk64us61PUyJ7iTEkV4y2GqpHSi8ejWJRnZwsX6XRTZSfUKsop', 'BC1YLi9cLKJkZ7pLyvLz14dAeJfdXvhYYRRQxZRvCXmchuQpNU7ovQn', 'BC1YLhmfDtNX88bmVdiWEypafM2nRcyFHMeoW9gy8TT5PbNCevXkT8L', 'BC1YLiqkd6TLPAStVYciCJxudJy3JQq83X2Qp4Banc8HE7K68up6Qzs', 'BC1YLjTPoBX5Ajma3yaMLUnMfjsYvErtT6v18NQ467MdpohhXSDuQ5T', 'BC1YLjWERF3xWcAD3SeCqtnRwF3FvhoXScZmF5TECd98qeCZpEzgsJD', 'BC1YLgzcfyi5GZoMb9xoVzDCMy9KEzzvTqoJzRrVDfhWE2FfFubxaVm', 'BC1YLhBLE1834FBJbQ9JU23JbPanNYMkUsdpJZrFVqNGsCe7YadYiUg',  'BC1YLirtb7CjNwVmWEt7t1487Qpo4LoPBDEGvfqYwXXZcj2dDLNMBVU', 'BC1YLiLhFcR5Jct4qXGaqVjhFgHzwE8fMKX9diibqzqmY78ZavNvCm5', 'BC1YLgNC8GkLTpzaJzq9GueaBsPNYH72PzmZ7YcfPCGy3g1ZFwXqdWK', 'BC1YLgdHdP7Q1L3TwUhjrQ28YCawU5e8RcGPvhY12DRtKHNkCxNxdbG', 'BC1YLhhwL8D3SE1ABwgWuwUjjFYGF64hsYHuToLyhPguDbGG5WhNDF3', 'BC1YLgNhcE7XKy4ypUkcvLeCLTBUdW49oz4aSKnUkjztU7GQyyCYweq'];
const publicKeysToFollow = ['BC1YLg3LGWznB8Qhw3u56DKtFTeUz2qtwgN7BNuAZVzUNKkhDhNApUM','BC1YLjGBU1pv4Wzc37r7qp4Xw9ZWHEzQ2Z4L6YBP9CVDfTWmWFaxYoD', 'BC1YLh3xfZeXxLNnMaMwhvnTBWozbyoWbDzzyk5ydh6rikNdzPuYEY4', 'BC1YLi3PRWAFHHWjVxfAivYQbAiXoGnsLkeLkpejxSuiqgiyfxMgYXo', 'BC1YLiuKfzE6HjurKQi156kzhUo8LGQWDUvudhkEPuqDZWe1NrdeLmV', 'BC1YLfiUNarmxM7BAGAFHTvp6oDgjF4hMP3ZaNhkdn1LdDhncWzQT6w', 'BC1YLgUt48Tcaahw2kFCuNh31Kv2Ep2KFKn2CXjbPET5dZYybM5gXhg', 'BC1YLfiuB9oWiHDxntaWJWKkYCCQ7Uo2qWfLuJY4L5tP2rX7dunkZwS', 'BC1YLhM4vH9FTepmEm9jCBrKmR46vW5n517YEopsuLT49JQHw674KE2', 'BC1YLiwZ1bd8xdUmXPoLMJtBVEN9hGUvayATeDfRq4Upwed6N2YMi68', 'BC1YLgfU6jFEoEW5FyDtBesygjyF6k5ULt2ycp5YKPLGuTMJhLb5qHo', 'BC1YLjUwv6pvUJVKhaNWwUPWgu8AfhaNJcof55VLx8cFuegUF4ZGACT', 'BC1YLi6wzmjQ7vKzj5jHY5CPXwfEJ9cUEK4Z8JnRqS3vqbYbszAbhuK'];

async function followMultipleKeys() {
  if (!executeRecaptcha) {
    console.error('Execute recaptcha not yet available');
    setApiResponse('Are you a bot?');
    setIsSubmitting(false);
    return;
  }

  const token = await executeRecaptcha('submit');  // The action is 'submit' here.
  if (!token) {
    console.error('reCAPTCHA validation failed.');
    setApiResponse('Are you a bot?');
    setIsSubmitting(false);
    return;
  }
  // Check if the user has permissions
  const hasPermission = await identity.hasPermissions({
    GlobalDESOLimit: 10000000, // 0.01 DESO
    TransactionCountLimitMap: {
      FOLLOW: 50,
    },
  });
  if (!hasPermission) {
    // Request permissions from the user
    await identity.requestPermissions({
    GlobalDESOLimit: 10000000, // 0.01 DESO
    TransactionCountLimitMap: {
      FOLLOW: 50,
    },
    });
  }
  const followerPublicKey = currentUser.PublicKeyBase58Check;
  // Go ahead and close - other stuff happens in the background
  setIsNewUserNowExplore(true);
  setIsNewUserJustClaimed(false);
  // First, make the POST request
  try {
    const response = await fetch('https://kzj8hu7m70.execute-api.us-west-2.amazonaws.com/production/follow', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        public_key: followerPublicKey,
        captcha_site_key: token
       })
    });
    
    const data = await response.json();

    if (data.statusCode !== 200) {
      throw new Error('Failed to make the POST request');
    }
  } catch (error) {
    console.error('Error with POST request:', error);
    return;  // If the POST request fails, we don't continue
  }

  // Shuffle the creatorsPool array
  const shuffled = creatorsPool.sort(() => 0.5 - Math.random());

  // Take the first 10 items from shuffled creatorsPool
  const selectedCreators = shuffled.slice(0, 9);

  // Get DC team public keys
  const theDCteam = await getUsersStateless({
    PublicKeysBase58Check: ['BC1YLjVbWMR8agP51We2HcMuhBw9ZY4e6TmgH2u5gkxzEMrxeKQWB4C'],
  });
  console.log(theDCteam)
  // Combine selected creators and publicKeysToFollow
  const allKeysToFollow = [...selectedCreators, ...publicKeysToFollow];

  const followPromises = allKeysToFollow.map(publicKey => {
    const request = {
      "IsUnfollow": false,
      "FollowedPublicKeyBase58Check": publicKey,
      "FollowerPublicKeyBase58Check": followerPublicKey
    };

    return updateFollowingStatus(request);
  });

  const responses = await Promise.all(followPromises);
  return responses;
}

const testFollow = async () => {
  // Get DC team public keys
  const theDCteam = await getUsersStateless({
    PublicKeysBase58Check: ['BC1YLjVbWMR8agP51We2HcMuhBw9ZY4e6TmgH2u5gkxzEMrxeKQWB4C'],
  });
  console.log(theDCteam)
}

const handleClaimClick = async () => {
  console.log("Clicked Claim");
  let nftLimitMap = {};
      nftLimitMap[postHashHex] = {};
      nftLimitMap[postHashHex][0] = {
          any: 1,
      };
  try {
        console.log("Getting permission")
          try {
              await identity.requestPermissions({
                  GlobalDESOLimit: 10000000, // 0.01 DESO
                  TransactionCountLimitMap: {
                      BASIC_TRANSFER: 2,
                      SUBMIT_POST: 2,
                      FOLLOW: 50,
                      LIKE: 1,
                  },
                  "NFTOperationLimitMap": nftLimitMap,
              });
          } catch (permissionError) {
              console.error('Error during permission request:', permissionError);
              setApiResponse('Permission request failed.');
              setIsSubmitting(false);
              return;
          }

  } catch (error) {
      handleIdentityError(error);
      setApiResponse('There was an error.');
      setIsSubmitting(false);
      return;
  }
  try {
      if (!executeRecaptcha) {
          console.error('Execute recaptcha not yet available');
          setApiResponse('Are you a bot?');
          setIsSubmitting(false);
          return;
      }

      let token;
      try {
          token = await executeRecaptcha('submit'); // The action is 'submit' here.
      } catch (error) {
          console.error('Error while executing reCAPTCHA:', error);
          setApiResponse('Are you a bot?');
          setIsSubmitting(false);
          return;
      }

      if (!token) {
          console.error('reCAPTCHA validation failed.');
          setApiResponse('Are you a bot?');
          setIsSubmitting(false);
          return;
      }
      setApiResponse(null);
      setIsSubmitting(true);
      console.log("Submitting")

      console.log("awating derived data")

      const derivedData = await getDerivedData();
      let derived_key = derivedData.derivedPublicKey;
      let derived_seed = derivedData.derivedSeedHex;
      let expirationBlock = derivedData.expirationBlock;
      let accessSignature = derivedData.accessSignature;
      let transactionSpendingLimitHex = derivedData.transactionSpendingLimitHex;

      const payload = {
          public_key: currentUser.PublicKeyBase58Check,
          post_hash_hex: postHashHex,
          derived_public_key: derived_key,
          derived_seed_hex: derived_seed,
          expirationBlock: expirationBlock,
          accessSignature: accessSignature,
          transactionSpendingLimitHex: transactionSpendingLimitHex,
          captcha_site_key: token,
          captcha: "",
          cloudflare_turnstine_token: turnstileToken
      };
      console.log("Claiming")

      fetch('https://kzj8hu7m70.execute-api.us-west-2.amazonaws.com/production/claim', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
      })
          .then(response => response.json())
          .then(data => {
              const responseBody = JSON.parse(data.body);  // Parse the stringified JSON body
              setApiResponse(responseBody.message);
              setIsSubmitting(false);
              if (data.statusCode === 200) {
                  setIsSubmissionSuccessful(true);
                  setUserHasClaimed(true);
                  if (isNewUser) {
                      setShowNewUserModal(true);
                      setIsNewUserJustClaimed(true);
                  } else {
                      party.confetti(cardRef.current);
                  }
              } else if (data.statusCode === 404) {
                  console.log("404 returned but considering submission successful");
                  setIsSubmissionSuccessful(true);
              }
          })
          .catch(error => {
              console.error('Error during claim:', error);
              setApiResponse('Sorry, there was an error. Please try again later.');
              setIsSubmitting(false);
          });

  } catch (error) {
      console.error('Unexpected error in handleClaimClick:', error);
      setApiResponse('An unknown error occurred. Please try again later.');
      setIsSubmitting(false);
  }
};

  function handleIdentityError(error) {
    if (error.message && error.message.includes('RuleErrorInsufficientBalance')) {
        alert("Sorry, you don't have enough DeSo to claim this NFT!");
    } else {
        const errorMessage = error.message ? error.message.split(':').pop().trim() : "An unknown error occurred";
        alert(errorMessage);
    }
}
  
  useEffect(() => {
    
    setIsSubmissionSuccessful(false);
    setApiResponse(null);
    // Extract posthashhex from the URL
    const postHashHex = window.location.pathname.split('/claim/')[1];
    setPostHashHex(postHashHex);

    // Check if the user has a profile
    if (currentUser && currentUser.PublicKeyBase58Check) {
      fetch('https://node.deso.org/api/v0/get-single-profile', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              PublicKeyBase58Check: currentUser.PublicKeyBase58Check
          })
      })
      .then(response => response.json())
      .then(data => {
          if (data.error && data.error.includes('GetSingleProfile: could not find profile')) {
              setUserHasNoProfile(true);
              console.log("User has no profile")
              setIsModalVisible(true);
          } else {
              setUserHasNoProfile(false);
              setIsModalVisible(false);
          }
      })
      .catch(error => {
          console.error('Error fetching profile data:', error);
      });
    }
  
    // Check if postHashHex exists and then make the POST request
    if (postHashHex) {
      fetch('https://node.deso.org/api/v0/get-nft-entries-for-nft-post', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ PostHashHex: postHashHex })
      })
      .then(response => response.json())
      .then(data => {
        // Check if the response has an "error" property
        if (data.error) {
          setApiError(data.error);  // save the error to state
          return;  // early exit from the function so that the subsequent logic doesn't run
        }
        // Check if there's any NFTEntryResponses with the specified OwnerPublicKeyBase58Check
        
        const count = data.NFTEntryResponses.filter(entry => entry.OwnerPublicKeyBase58Check === process.env.REACT_APP_OWNER_PUBLIC_KEY).length;
        
        ownedCopiesCountRef.current = count;
        if (ownedCopiesCountRef.current < 1) {
          setAllNFTsClaimed(true);
        }
        // If the user is logged in, check if they have claimed
        if (currentUser && currentUser.PublicKeyBase58Check) {
          const hasUserClaimed = data.NFTEntryResponses.some(entry => entry.OwnerPublicKeyBase58Check === currentUser.PublicKeyBase58Check);
          setUserHasClaimed(hasUserClaimed);
        }
  
        // Make the second API POST request to get the additional data
        return fetch('https://node.deso.org/api/v0/get-single-post', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ PostHashHex: postHashHex })
        });
      })
      .then(response => response ? response.json() : null)
      .then(postData => {
        if (postData && postData.PostFound) {
          // Store the ImageURLs and Edition value to the state
          const isClaimable =
          postData.PostFound.PosterPublicKeyBase58Check === process.env.REACT_APP_OWNER_PUBLIC_KEY &&
          postData.PostFound.PostExtraData?.Claimable === "Yes";
          setHasClaimableNFT(isClaimable);
          setImageURLs(postData.PostFound.ImageURLs);
          setEdition(postData.PostFound.PostExtraData.Edition);
          setSeries(postData.PostFound.PostExtraData['Collection Name']);
          numNFTCopiesRef.current = postData.PostFound.NumNFTCopies;

          numNFTCopiesClaimedRef.current = numNFTCopiesRef.current - ownedCopiesCountRef.current 

        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [currentUser]);

  return (
    <>
    
      { apiError ? <p>Error: {apiError}</p> : null }
      { hasClaimableNFT || userHasClaimed ? 
        <>
          <div className="flex justify-center">
            <Card className="lg:w-1/3 mb-10" ref={cardRef}>
              <CardHeader className="px-6 pb-1">
              { !imageLoaded && <Skeleton className="w-full h-0 pt-[100%] rounded-t-md" /> }
              <img 
                src={imageURLs[0]} 
                alt="NFT Art"
                className="w-full object-cover rounded-t-md"
                onLoad={() => setImageLoaded(true)} // Set the imageLoaded state to true once the image loads
                style={{ display: imageLoaded ? 'block' : 'none' }} // Hide the image until it's loaded
              />
                <CardTitle>
                  <div className="flex justify-between items-center">
                  <span className="neoFont">{series && series !== "" ? series : "ArtExplosion"}</span>
                  <span className="neoFont">{edition}</span>
                  </div>
                  {
                 ownedCopiesCountRef.current !== 0 && (
                   <p className="leading-none text-sm text-muted-foreground tracking-tight flex justify-between items-center">
                     <span className=""></span>
                     <span className="text-xs">{`${numNFTCopiesClaimedRef.current} of ${numNFTCopiesRef.current} Claimed`}</span>
                   </p>
                 )
                }
                </CardTitle>
              </CardHeader>
              <CardContent>
              { 
                  (currentUser && !userHasClaimed && !allNFTsClaimed) 
                  ? 
                  <>
                      <Button
                          size="lg"
                          type="submit"
                          disabled={!turnstileToken || isSubmitting || isLoading}
                          onClick={handleClaimClick}
                      >
                          {
                              (isSubmitting || isLoading)
                              ? (
                                  <>
                                      <Loader2 className="animate-spin" />
                                      Please Wait
                                  </>
                                )
                              : 'Claim'
                          }
                      </Button>
                  </>
                  : null
              }
    { !currentUser &&
    <>
    <p className="m-2 mb-3 italic">In order to claim this NFT you need to login with your DeSo account. Don't have an account, no problem, you can create one for free!</p>
    <Button size="lg" className="mr-4 mb-2" onClick={() => identity.login()} variant="outline">Login</Button><Button size="lg" className="mb-2" onClick={signUpTutorial}>Create Account</Button>
    <Button size="lg" className="mr-4 mb-2" onClick={() => identity.testFollow} variant="outline">Test</Button>
    </>
    }

    

              </CardContent>
            </Card>
            
          </div>

          
        </>
        : null
      }
      <div className="flex justify-center items-center">
      <Turnstile
      sitekey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY}
      executution="execute"
      onLoad={(widgetId, bound) => {
        // before:
        window.turnstile.execute(widgetId);
        // now:
        bound.execute();
      }}
      onVerify={(token) => {
        // Update the state variable with the received token.
        setTurnstileToken(token);
      }}
    />
    </div>
    {isModalVisible && (
  <>
    <div className="fixed inset-0 bg-black opacity-70 z-2"></div>
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black p-8 rounded-lg shadow-lg w-full max-w-2xl md:w-3/4">
        <h2 className="text-2xl font-bold text-white mb-4">Awesome, you're almost ready to claim this FREE NFT!</h2>
        <p className="text-gray-300">You just need to choose a username and upload a profile image:</p>
    
        {/* Username Input */}
        <div className="flex flex-col items-start w-full lg:w-3/4 gap-1.5 mx-auto mt-3">
          <Label htmlFor="username" className="text-1xl">Choose a username:</Label>
          <Input 
            id="userIdentifierDeso" 
            name="userIdentifierDeso" 
            type="text" 
            placeholder="Enter username"
            value={username}
            onChange={handleUsernameChange}
            autoComplete="off"
          />
          <p className={canSubmit ? 'text-green-500' : 'text-red-500'}>
            {availabilityMessage}
          </p>
        </div>
    

        {/* File Input */}
        <div className="flex items-center gap-1.5 w-full lg:w-3/4 mt-4 mx-auto justify-center">
          <label className="flex-grow relative block">
          <div className="items-start text-left mb-1.5">Choose Profile Image:</div>
            <input id="picture" type="file" onChange={handleImageChange}
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:bg-primary file:text-black hover:file:bg-primary/90 disabled:opacity-50 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 file:cursor-pointer" />
          </label>
          {uploadedImage && (
            <div>
              <Avatar className="h-[3.875rem] w-[3.875rem]">
                <AvatarImage src={uploadedImage} alt="Uploaded preview" />
                <AvatarFallback>U</AvatarFallback>
              </Avatar>
            </div>
          )}
        </div>

        {/* Submit Button */}
          <div className="mt-4 flex flex-col items-center"> 
            <Button
              disabled={isSubmitting || !canSubmit} 
              onClick={handleUpdateProfile}
            >
              {isSubmitting 
                ? (
                  <>
                    <Loader2 className="animate-spin" />
                    Please Wait...
                  </>
                ) 
                : 'Submit'
              }
            </Button>
            
            {modalErrorMessage && (
              <p className="mt-2 text-red-500">{modalErrorMessage}</p>
            )}
        </div>
      </div>
    </div>
  </>
)}
{isNewUserJustClaimed && apiResponse && userHasClaimed && (
  <>
    <div className="fixed inset-0 bg-black opacity-70 z-2" onClick={() => setIsNewUserJustClaimed(false)}></div>
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="bg-black p-8 rounded-lg shadow-lg relative w-full max-w-2xl md:w-3/4 border">
        
        {/* Close Button */}
        <button className="absolute right-2 font-bold top-2 text-white" onClick={() => setIsNewUserJustClaimed(false)}>
        <X size={18} />
        </button>

        <h2 className="text-3xl font-bold text-white mb-4" ref={cardRef}>You snagged this ArtExplosion NFT! 🎉</h2>
        <p className="text-gray-300 pb-3">Dive deeper – Now that you have an account you should follow some people. <br></br>Check out these rad creators we think you'll vibe with:</p>
        <div className="flex items-center justify-center space-x-2">
          <DisplayAvatarsFollow />
          <span className="text-gray-300">+15 others</span>
        </div>
    
         
        
         <div className="mt-4 flex flex-row justify-center space-x-4 items-center pt-3"> 
         {/* Cancel Button */}
         <Button variant="outline" onClick={() => setIsNewUserJustClaimed(false)}>
             No thanks
           </Button>
           {/* Submit Buttons */}
           <Button
             disabled={isSubmitting} 
             onClick={() => {
               if (!isSubmitting) {
                 setIsSubmitting(true);
                 followMultipleKeys()
                   .then(responses => {
                     console.log("All keys followed!", responses);
                     setIsSubmitting(false);
                   })
                   .catch(error => {
                     console.error("Error following keys:", error);
                     setIsSubmitting(false);
                   });
               }
             }}
           >
             {isSubmitting 
               ? (
                 <>
                   <Loader2 className="animate-spin" />
                   Please Wait...
                 </>
               ) 
               : 'Follow All'
             }
           </Button>
         
           

            
          {modalErrorMessage && (
            <p className="mt-2 text-red-500">{modalErrorMessage}</p>
          )}
        </div>
      </div>
    </div>
  </>
)}
{isNewUserNowExplore && (
  <>
    <div className="fixed inset-0 bg-black opacity-70 z-2" onClick={() => setIsNewUserNowExplore(false)}></div>
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="bg-black p-8 rounded-lg shadow-lg relative w-full max-w-2xl md:w-3/4 border">
        
        {/* Close Button */}
        <button className="absolute right-2 font-bold top-2 text-white" onClick={() => setIsNewUserNowExplore(false)}>
        <X size={18} />
        </button>

        <h2 className="text-3xl font-bold text-white mb-4" ref={cardRef}>Awesome, now go explore your DeSo social feed!</h2>
        <p className="text-gray-300 pb-3">DeSo is more than just NFTs, there's a whole social network of people around the world waiting for your first post! You'll use your same login details across all apps. Or learn more about DeSo by checking out a free video course. Welcome to the revolution!</p>
    
         
        
         <div className="mt-4 flex flex-row justify-center space-x-4 items-center pt-3"> 
         {/* Learn More Button */}
         <Button variant="outline" onClick={() => {
             setIsNewUserNowExplore(false); // Setting the state to false
             window.open("https://www.youtube.com/watch?v=zshgxgJ2gUM&list=PLuSeo-NlSN_lXF3wXTmLxnsQQsyIqlb8r", "_blank");
           }}
         >
           Learn more
         </Button>
         {/* Submit Buttons */}
         <Button
           onClick={() => {
             setIsNewUserNowExplore(false); // Setting the state to false
             window.open("https://diamondapp.com/browse?feedTab=Following", "_blank");
           }}
         >
           Explore DeSo
         </Button>
         
           

            
          {modalErrorMessage && (
            <p className="mt-2 text-red-500">{modalErrorMessage}</p>
          )}
        </div>
      </div>
    </div>
  </>
)}
{userHasClaimed && (
  <>
  <div>
  <h1 className="text-2xl md:text-4xl text-center mt-16 mb-2 font-bold">Welcome to the world of Decentralized Social:</h1>
  </div>
  <div className="lg:w-2/3 justify-center lg:items-center mx-auto">
  <div className=' aspect-video ' >
    <iframe 
        className=' h-full w-full rounded-lg'
        src="https://www.youtube.com/embed/kPPb_S5Ry5E?si=jrNayaofOeRORN7S" 
        width="100%" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen> 
     </iframe>
</div> 
  </div>
  <div>
  <h1 className="text-2xl md:text-4xl text-center mt-16 mb-2 font-bold">All these apps and more use DeSo:</h1>
  <p className="mb-8 italic">You can login using your DeSo account on all these apps seemlessly!</p>
  </div>

<div className="lg:flex justify-center lg:items-center lg:w-2/3 mx-auto lg:space-x-6">

  <div className="flex-1 mb-4">
    <Card>
      <CardHeader className="flex justify-center items-center">
        <CardTitle>
          <a href="https://desocialworld.com" target="_blank" rel="noopener noreferrer">
            <img src={desocialWorldLogo} alt="DeSocialWorld" className="App-logo w-full" />
          </a>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-3">We make it easy for all non-English speaking people to use Decentralized Social.</p>
        <a href="https://desocialworld.com" target="_blank" rel="noopener noreferrer">
          <Button>Visit</Button>
        </a>
      </CardContent>
    </Card>
  </div>

  <div className="flex-1 mb-4">
    <Card>
      <CardHeader className="flex justify-center items-center">
        <CardTitle>
          <a href="https://nftz.me" target="_blank" rel="noopener noreferrer">
            <img src={nftzLogo} alt="NFTz" className="App-logo w-full" />
          </a>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-3">NFTz.me is a DeSo marketplace and community that aims to pave the way to seamlessly blend NFTs with Web3 services.</p>
        <a href="https://nftz.me" target="_blank" rel="noopener noreferrer">
          <Button>Visit</Button>
        </a>
      </CardContent>
    </Card>
  </div>

  <div className="flex-1 mb-4">
    <Card>
      <CardHeader className="flex justify-center items-center">
        <CardTitle>
          <a href="https://diamondapp.com" target="_blank" rel="noopener noreferrer">
            <img src={diamondLogo} alt="Diamond" className="App-logo w-full" />
          </a>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-3">Join the future of web3 and social media onchain. Built on DeSo.</p>
        <a href="https://diamondapp.com" target="_blank" rel="noopener noreferrer">
          <Button>Visit</Button>
        </a>
      </CardContent>
    </Card>
  </div>

</div>

  </>
)}
{showOnboardNewUserModal && (
  <>
  <div className="fixed inset-0 bg-black opacity-70 z-2" onClick={() => setShowOnboardNewUserModal(false)}></div>
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="bg-black p-8 rounded-lg shadow-lg relative w-full max-w-2xl md:w-3/4 border">
        
        {/* Close Button */}
        <button className="absolute right-2 font-bold top-2 text-white" onClick={() => setShowOnboardNewUserModal(false)}>
        <X size={18} />
        </button>
          
          
          {currentPage === 1 && (
            <>
              <h2 className="text-2xl font-bold text-white mb-4">Signing up for a DeSo Account is easy!</h2>
              <p className="text-gray-300 mb-2">Step 1: When you click "Sign Up" a window will pop-up and you can choose to sign up with your Google account, MetaMask or you can "Sign up with DeSo Seed":</p>
              <img src={desoStep1} alt="Step 1" className="rounded border bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800" />
            </>
          )}
          
          {currentPage === 2 && (
            <>
              <p className="text-gray-300 mb-2">Step 2: If you select to use a Seed Phrase be sure and copy your Seed Phrase in a safe place (you’ll need this to login - it’s like a password). Then on the next screen paste the Seed Phrase back into the text box.</p>
              <img src={desoStep2} alt="Step 2" className="rounded border bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800" />
            </>
          )}

          {currentPage === 3 && (
            <>
              <p className="text-gray-300 mb-2">Step 3: Then complete the captcha to receive some free DeSo and approve the signing access prompt. Ready? Go ahead and click the "Create Account" button!</p>
              <img src={desoStep3} alt="Step 3" className="rounded border bg-white p-1 dark:border-neutral-700 dark:bg-neutral-800" />
            </>
          )}

            <div className="mt-4 flex justify-between items-center"> 
            
            {currentPage > 1 ? (
                <Button onClick={prevPage}>
                    Back
                </Button>
            ) : (
                <span></span>
            )}
            
            {currentPage < 3 ? (
                <Button onClick={nextPage}>
                    Next
                </Button>
            ) : (
              <Button onClick={() => {
                identity.login();
                setShowOnboardNewUserModal(false);
            }}>
                Create Account
            </Button>
            )}
            
            {modalErrorMessage && (
                <div className="w-full mt-2 text-red-500 text-center">{modalErrorMessage}</div>
            )}
            </div>
        </div>
      </div>

  </>
)}


    </>
    
);
}